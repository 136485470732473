import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import Accordion from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS API Gateway with AWS Cognito & Cloudflare Integration",
  "path": "/knowledge/tech/aws",
  "date": "2023-02-17T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h2 {...{
          "id": "overview",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#overview",
            "aria-label": "overview permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Overview`}</h2>
        <p>{`This document provides a step-by-step guide to working with the provided API Gateway and authenticating the user with AWS Cognito, including how to integrate with Cloudflare. By following the instructions in this document, you will be able to create a fully working CDK stack that creates and connects AWS Cognito and AWS API Gateway`}</p>
        <h2 {...{
          "id": "cloudflare-integration",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#cloudflare-integration",
            "aria-label": "cloudflare integration permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Cloudflare Integration`}</h2>
        <p>{`Cloudflare Integration means that Cloudflare will act as the frontend for our AWS API Gateway. If a client want to send a request to API Gateway, the request will go through Cloudflare first and then AWS API Gateway, and if the request is authorized, it'll hit the AWS Console.`}</p>
        <h3 {...{
          "id": "request-from-client-to-aws-console-diagram",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#request-from-client-to-aws-console-diagram",
            "aria-label": "request from client to aws console diagram permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Request from Client to AWS Console diagram`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "844px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/496f44b87652dc719a24e2eef3c1b2d9/33e10/api-gateway-auth-diagram.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "33.666666666666664%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVQoz4VQW27DMAzr/Y8z7L8H2FcPMGBAUztGHsvDjh1HLgcKS4oB6/ZB0yQkWvJJRFBKUXjvsSzLoXcvxvjDyzljnmdlambsOPFgCBuNMWjbFikl1SEE9bquU4+a4dM0qU+mv23bI5AFwzCg73sNZhPvdV2jaZrDM+aG2lpUVYVpHNXn49ba40FOq4EEC8hcYw/ZJ6LnQ8QcFkVaM9Z1PbYgU2sgx2UDsf8nmf5jlYzsG3y6D4T+BonDk7rvP/wT5Q5JI+L7Gde3FzSXV4i7QKT8Wv9/oBRsOaEEB/EOxTvI0j2t/wJTtB+4mm+bzwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "api gateway with auth diagram",
                "title": "api gateway with auth diagram",
                "src": "/static/496f44b87652dc719a24e2eef3c1b2d9/33e10/api-gateway-auth-diagram.png",
                "srcSet": ["/static/496f44b87652dc719a24e2eef3c1b2d9/5a46d/api-gateway-auth-diagram.png 300w", "/static/496f44b87652dc719a24e2eef3c1b2d9/0a47e/api-gateway-auth-diagram.png 600w", "/static/496f44b87652dc719a24e2eef3c1b2d9/33e10/api-gateway-auth-diagram.png 844w"],
                "sizes": "(max-width: 844px) 100vw, 844px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "creating-a-fully-working-cdk-stack",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#creating-a-fully-working-cdk-stack",
            "aria-label": "creating a fully working cdk stack permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating a fully working CDK stack`}</h2>
        <p>{`The follow CDK stack create and integrate AWS Cognito and AWS API Gateway. The steps are:`}</p>
        <ol>
          <li parentName="ol">{`Create Cognito User Pool`}
            <ol parentName="li">
              <li parentName="ol">{`Create an app client`}</li>
              <li parentName="ol">{`Create a domain`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Create a REST API Gateway`}
            <ol parentName="li">
              <li parentName="ol">{`Create a resource `}<inlineCode parentName="li">{`/ditto`}</inlineCode></li>
              <li parentName="ol">{`Create a `}<inlineCode parentName="li">{`GET`}</inlineCode>{` HTTP Integration for the resource`}</li>
              <li parentName="ol">{`Create Cognito authorizer for the API Gateway`}
                <ul parentName="li">
                  <li parentName="ul">{`Uses the created Cognito User Pool instance to create the authorizer`}</li>
                </ul>
              </li>
              <li parentName="ol">{`Set the authorization handler for the resource's `}<inlineCode parentName="li">{`GET`}</inlineCode>{` method as the created Cognito's authorizer`}</li>
            </ol>
          </li>
        </ol>
        <h3 {...{
          "id": "cdk-code",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#cdk-code",
            "aria-label": "cdk code permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CDK Code`}</h3>
        <Accordion summaryText="View code" mdxType="Accordion">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`import * as cdk from 'aws-cdk-lib'
import * as apiGateway from 'aws-cdk-lib/aws-apigateway'
import * as cognito from 'aws-cdk-lib/aws-cognito'

export class CdkStack extends cdk.Stack {
  constructor(scope: cdk.App, id: string, props?: cdk.StackProps) {
    super(scope, id, props)

    // Create Cognito user pool
    const USER_POOL_ID = 'APIgatewayUserPool'
    const userPool = new cognito.UserPool(this, USER_POOL_ID, {
      // Allow users to sign-up
      selfSignUpEnabled: true,
      autoVerify: {
        email: true,
      },
      standardAttributes: {
        // By default, Cognito verify sign-ups. When the email isn't required-
        // Cognito hosted UI won't display it when singing up
        email: {
          required: true,
        },
      },
    })

    // Add an App Client. Necessary for the hosted API and-
    // for other apps to interact with Cognito API programmatically
    userPool.addClient('APIgatewayUserPoolClient')

    // Create a domain for the Hosted UI
    userPool.addDomain('Domain', {
      cognitoDomain: {
        // "onetwothree" are meant to make the prefix unique
        domainPrefix: 'apigatewayauthonetwothree',
      },
      // You could provide a custom domain instead
      // customDomain: 'myClient.website.com'
    })

    // Create REST API Gateway
    const api = new apiGateway.RestApi(this, 'ditto-api')

    // Declare HTTP Integration type with target as pokeAPI to be used later
    const httpIntegration = new apiGateway.HttpIntegration(
      'https://pokeapi.co/api/v2/pokemon/ditto'
    )

    // Add \`/ditto\` resource to the API Gateway
    const dittoResource = api.root.addResource('ditto')

    // Create Cognito authorizer for the API Gateway
    const cognitoAuth = new apiGateway.CognitoUserPoolsAuthorizer(
      this,
      'ApiGatewayAuth',
      {
        cognitoUserPools: [userPool],
      }
    )

    // Create a protected GET method with Cognito authorizer for \`/ditto\` resource
    dittoResource.addMethod('GET', httpIntegration, {
      authorizer: cognitoAuth,
      authorizationType: apiGateway.AuthorizationType.COGNITO,
    })
  }
}
`}</code></pre>
        </Accordion>
        <h2 {...{
          "id": "demo-api-gateway-with-auth-enabled",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#demo-api-gateway-with-auth-enabled",
            "aria-label": "demo api gateway with auth enabled permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Demo API Gateway with auth enabled`}</h2>
        <p>{`The following steps were performed to create the API Gateway with auth enabled:`}</p>
        <ol>
          <li parentName="ol">{`Create a REST API`}</li>
          <li parentName="ol">{`Create a resource and a method`}</li>
          <li parentName="ol">{`Create a Congito user pool`}</li>
          <li parentName="ol">{`Create Cognito authorizer from the Authorizers page`}</li>
          <li parentName="ol">{`Set the Authorization option in the resource's Method Request to the created Cognito authorizer`}</li>
        </ol>
        <h3 {...{
          "id": "working-with-the-api-gateway",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#working-with-the-api-gateway",
            "aria-label": "working with the api gateway permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Working with the API Gateway`}</h3>
        <blockquote>
          <p parentName="blockquote">{`The API Gateway has a custom URL because it'll later be integrated with Cloudflare; Explained in the next section.`}</p>
        </blockquote>
        <p><strong parentName="p">{`API Gateway URL:`}</strong>{` `}<a parentName="p" {...{
            "href": "https://api.flacial.dev"
          }}>{`https://api.flacial.dev`}</a></p>
        <p>{`The API Gateway has auth enabled with AWS Cognito. It has one resource named `}<inlineCode parentName="p">{`/ditto`}</inlineCode>{` that has a GET method with an HTTP integration that respond with a Pokemon from PokeAPI. The resource is protected and to access it, you'll have to provide a JWT token in the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header.`}</p>
        <p>{`You can get the JWT token by signing in to Congito's app client with the following credentials:`}</p>
        <p><strong parentName="p">{`Cognito app client URL:`}</strong>{` `}<a parentName="p" {...{
            "href": "https://apigatewayauthonetwo.auth.us-east-1.amazoncognito.com/login?client_id=24522a8kqqaq4ujnb29v48eptu&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fexample.com"
          }}>{`https://apigatewayauthonetwo.auth.us-east-1.amazoncognito.com/login?client_id=24522a8kqqaq4ujnb29v48eptu&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fexample.com`}</a></p>
        <p><strong parentName="p">{`username:`}</strong>{` superdude
`}<strong parentName="p">{`password:`}</strong>{` Superd3d$`}</p>
        <p>{`Once you sign in, you'll be redirected to `}<a parentName="p" {...{
            "href": "https://example.com"
          }}>{`https://example.com`}</a>{`, the URL has a query parameter with the name `}<inlineCode parentName="p">{`id_token`}</inlineCode>{`, this is your JWT token. Save it somewhere.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Usually, it doesn't contain the id_token, but if we set the response_type query parameter as "token" it'll include it`}</p>
        </blockquote>
        <p>{`e.g, `}<inlineCode parentName="p">{`https://example.com/#id_token=<JWT_TOKEN>...`}</inlineCode></p>
        <p>{`Using Postman or your favorite API testing tool, send a request to `}<a parentName="p" {...{
            "href": "https://api.flacial.dev/ditto"
          }}>{`https://api.flacial.dev/ditto`}</a>{` with/without the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header wit the value of `}<inlineCode parentName="p">{`id_token`}</inlineCode>{`:`}</p>
        <p>{`With:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`curl --location 'https://api.flacial.dev/ditto' \\
--header 'Authorization: <YOUR_ID_TOKEN>'
`}</code></pre>
        <p>{`Without:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`curl --location 'https://api.flacial.dev/ditto'
`}</code></pre>
        <h4 {...{
          "id": "common-errors",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#common-errors",
            "aria-label": "common errors permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Common errors`}</h4>
        <p>{`While creating/working with the API Gateway, you may encounter the following issues:`}</p>
        <ul>
          <li parentName="ul">{`If the JWT token is valid and you receive the response `}<inlineCode parentName="li">{`message: "unauthorized"`}</inlineCode>{`, the JWT token has likely expired. Re-sign in and use the new JWT token.`}</li>
          <li parentName="ul">{`Make sure when creating the Cloudflare certificate, you set the Hostnames as `}<inlineCode parentName="li">{`<subdomain>.<domain>`}</inlineCode>{` e.g, `}<inlineCode parentName="li">{`api.flacial.dev`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`flacial.dev`}</inlineCode>{`, otherwise, API Gateway won't be able to intercept your requests from Cloudflare.`}</li>
        </ul>
        <h2 {...{
          "id": "integrating-cloudflare-with-the-api-gateawy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#integrating-cloudflare-with-the-api-gateawy",
            "aria-label": "integrating cloudflare with the api gateawy permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Integrating Cloudflare with the API Gateawy`}</h2>
        <p>{`To do this, you'll have to follow the following steps:`}</p>
        <ol>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://developers.cloudflare.com/ssl/client-certificates/create-a-client-certificate/"
            }}>{`Generate a certificate in Cloudflare`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/acm/latest/userguide/import-certificate-api-cli.html"
            }}>{`Import the crertificate in AWS Certificate Manager (ACM)`}</a></li>
          <li parentName="ol">{`Create a custom domain from API Gateway `}<inlineCode parentName="li">{`Custom domain names`}</inlineCode>{` page`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`(this is why our API Gateway domain is api.flacial.dev)`}</strong></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://docs.amazonaws.cn/en_us/apigateway/latest/developerguide/how-to-edge-optimized-custom-domain-name.html"
                }}>{`https://docs.amazonaws.cn/en_us/apigateway/latest/developerguide/how-to-edge-optimized-custom-domain-name.html`}</a></li>
            </ul>
          </li>
          <li parentName="ol">{`Create an API mapping for your API Gateway and an optional path in your custom domain`}
            <ul parentName="li">
              <li parentName="ul">{`It's basically saying, whenever a request is sent to this custom domain, forward it the selected API Gateway and its stage.`}</li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-mappings.html"
                }}>{`https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-mappings.html`}</a></li>
            </ul>
          </li>
          <li parentName="ol">{`Add a `}<inlineCode parentName="li">{`CNAME`}</inlineCode>{` DNS record in your Cloudflare page that points a subdomain to the API Gateway domain name`}
            <ul parentName="li">
              <li parentName="ul">{`You can find the API Gateway domain name by going to the created custom domain and Configuration`}</li>
              <li parentName="ul"><span parentName="li" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "maxWidth": "1200px"
                  }
                }}>{`
      `}<a parentName="span" {...{
                    "className": "gatsby-resp-image-link",
                    "href": "/static/8879604302242cddc655b6cef850d9c2/4f9e8/aws-api-gateway-custom-domains-config.png",
                    "style": {
                      "display": "block"
                    },
                    "target": "_blank",
                    "rel": "noopener"
                  }}>{`
    `}<span parentName="a" {...{
                      "className": "gatsby-resp-image-background-image",
                      "style": {
                        "paddingBottom": "48.666666666666664%",
                        "position": "relative",
                        "bottom": "0",
                        "left": "0",
                        "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABTUlEQVQoz31SCW6DMBDk/09MUhHSNhw+OHxhG5jKmxhFROlKo7WH3bF3cFGLEbIf0HEOISUmpTBOE2Wl9b7/D6km1xWn0xnnyxeuVYXyWoELQUKy7+mAVJSFcz7iVbhomgZN20JrDWstfdDGgDGGuq6JizHCe08IIeyIIWKeZxIyxlBfoYwjMsW2bQQfAvphhHUPPkWqSQ25Li4RUgnKmZu9RzEpTaNY53akxr79wcBqcCHRMb6P/zpqPw1vXDF9MJqxDoy14FJiePqojIEymg5Me60NWZXWu4dvBifzJ4Xu+xf19YZ7WcH1I4IyCEojeE/jLzGStxnOORhrD4LpdkpB9wN4ecP9UmK8t7BMQLUMQQwI1oFxQSLH8A8P359AGkMKCd4xLCEC64ptWbCtK9Z1hZtnumX+iRnzJ8GUtTU0gnp69Ar9fCJHPnn4B/STAeL8ReGFAAAAAElFTkSuQmCC')",
                        "backgroundSize": "cover",
                        "display": "block"
                      }
                    }}></span>{`
  `}<img parentName="a" {...{
                      "className": "gatsby-resp-image-image",
                      "alt": "api gateway domain example",
                      "title": "api gateway domain example",
                      "src": "/static/8879604302242cddc655b6cef850d9c2/c1b63/aws-api-gateway-custom-domains-config.png",
                      "srcSet": ["/static/8879604302242cddc655b6cef850d9c2/5a46d/aws-api-gateway-custom-domains-config.png 300w", "/static/8879604302242cddc655b6cef850d9c2/0a47e/aws-api-gateway-custom-domains-config.png 600w", "/static/8879604302242cddc655b6cef850d9c2/c1b63/aws-api-gateway-custom-domains-config.png 1200w", "/static/8879604302242cddc655b6cef850d9c2/d61c2/aws-api-gateway-custom-domains-config.png 1800w", "/static/8879604302242cddc655b6cef850d9c2/97a96/aws-api-gateway-custom-domains-config.png 2400w", "/static/8879604302242cddc655b6cef850d9c2/4f9e8/aws-api-gateway-custom-domains-config.png 2432w"],
                      "sizes": "(max-width: 1200px) 100vw, 1200px",
                      "style": {
                        "width": "100%",
                        "height": "100%",
                        "margin": "0",
                        "verticalAlign": "middle",
                        "position": "absolute",
                        "top": "0",
                        "left": "0"
                      },
                      "loading": "lazy",
                      "decoding": "async"
                    }}></img>{`
  `}</a>{`
    `}</span></li>
            </ul>
          </li>
          <li parentName="ol">{`Set the encryption mode as Full`}
            <ul parentName="li">
              <li parentName="ul">{`This is because AWS only supports an SSL certification, and not a valid, publicly trusted certificate. `}<em parentName="li">{`This info require more research.`}</em></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://developers.cloudflare.com/ssl/origin-configuration/ssl-modes/"
                }}>{`https://developers.cloudflare.com/ssl/origin-configuration/ssl-modes/`}</a></li>
            </ul>
          </li>
        </ol>
        <h2 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://carocci-eugenio.medium.com/connect-cloudflare-to-aws-api-gateway-18e961a6e24f"
            }}>{`(Exeternal) Integrating AWS API Gateway with Cloudflare`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/api/v1/docs/aws-cognito-readme.html"
            }}>{`AWS CDK Cognito`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/api/v1/docs/aws-apigateway-readme.html"
            }}>{`AWS CDK API Gateway`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://developers.cloudflare.com/"
            }}>{`Cloudflare docs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=oFSU6rhFETk"
            }}>{`(External) Integrating AWS API Gateway with Cognito`}</a>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Recommended YouTube channel`}</strong></li>
            </ul>
          </li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      